var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.entity)?_c('v-dialog',{attrs:{"fullscreen":"","hide-overlay":"","transition":"dialog-bottom-transition","persistent":"","scrollable":""},model:{value:(_vm.open),callback:function ($$v) {_vm.open=$$v},expression:"open"}},[_c('v-card',[_c('v-toolbar',{attrs:{"flat":""}},[_c('v-btn',{attrs:{"icon":"","loading":_vm.processing,"disabled":_vm.processing},on:{"click":_vm.close}},[_c('v-icon',[_vm._v("mdi-keyboard-backspace")])],1),_c('v-toolbar-title',[_vm._v(_vm._s(_vm.$t('model.permission.permission') +' ('+(!_vm.editMode ? _vm.$t('base.addNew') : _vm.$t('base.edit'))+')'))]),_c('v-spacer')],1),_c('v-card-text',[_c('validation-observer',{ref:"form",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
var reset = ref.reset;
return [_c('form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.onSave)},"reset":function($event){$event.preventDefault();return reset($event)}}},[_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"4"}},[_c('validation-provider',{attrs:{"name":_vm.$t('model.permission.name'),"rules":"required|max:255"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"prepend-icon":"mdi-pencil","error-messages":errors,"placeholder":_vm.$t('model.permission.name'),"label":_vm.$t('model.permission.name')},model:{value:(_vm.entity.name),callback:function ($$v) {_vm.$set(_vm.entity, "name", $$v)},expression:"entity.name"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","sm":"4"}},[_c('validation-provider',{attrs:{"name":_vm.$t('model.permission.description'),"rules":"required|max:255"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"prepend-icon":"mdi-pencil","error-messages":errors,"placeholder":_vm.$t('model.permission.description'),"label":_vm.$t('model.permission.description')},model:{value:(_vm.entity.description),callback:function ($$v) {_vm.$set(_vm.entity, "description", $$v)},expression:"entity.description"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","sm":"4"}},[_c('validation-provider',{attrs:{"name":_vm.$t('model.permission.crud_table'),"rules":"required|max:255"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"prepend-icon":"mdi-pencil","error-messages":errors,"placeholder":_vm.$t('model.permission.crud_table'),"label":_vm.$t('model.permission.crud_table')},model:{value:(_vm.entity.crud_table),callback:function ($$v) {_vm.$set(_vm.entity, "crud_table", $$v)},expression:"entity.crud_table"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","sm":"4"}},[_c('v-switch',{attrs:{"label":_vm.entity.status ? _vm.$t('base.enable') : _vm.$t('base.disable')},model:{value:(_vm.entity.status),callback:function ($$v) {_vm.$set(_vm.entity, "status", $$v)},expression:"entity.status"}})],1),_c('v-col',{staticClass:"mt-6",attrs:{"cols":"12","align":"center"}},[_c('v-btn',{attrs:{"text":"","disabled":_vm.processing},on:{"click":_vm.close}},[_vm._v(" "+_vm._s(_vm.$t("base.cancel"))+" ")]),_c('v-btn',{attrs:{"color":"primary","type":"submit","text":"","disabled":_vm.processing}},[_c('v-icon',[_vm._v("mdi-lead-pencil")]),_vm._v(" "+_vm._s(_vm.$t("base.save"))+" ")],1)],1)],1)],1)],1)]}}],null,false,3270492005)})],1)],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }